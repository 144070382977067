.paidLabel {
  position: relative;
  // border-radius: 50%;
  flex-shrink: 0;
  display: block;
  position: relative;

  .background {
    // overflow: hidden;
    // border-radius: 100%;
    width: 100%;
    height: 100%;
    img {
      max-width: 100%;
      max-height: 100%;
      // border-radius: 100%;
    }
  }
  .freeMinutes {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 7px;
    color: #fff;
    position: absolute;
    top: -3px;
    width: 20px;
    height: 20px;
  }
}
