.tippedListWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  .tippedList {
    padding-top: 8px;
    padding-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: hidden;
    .tippedLabel {
      margin-right: 6px;
      margin-bottom: 3px;
    }
    .tippedIcon {
      margin-right: 3px;
    }
  }
  .loadmoreBtn {
    margin-right: 0 !important;
    padding: 3px 6px;
    .btnText {
      font-size: 14px;
      line-height: 18px;
      min-width: 40px;
      max-width: 80px;
      text-align: center;
      width: 100%;
    }
  }
}

@media screen and (max-width: 767px) {
  .tippedListWrapper .loadmoreBtn .btnText {
    font-size: 13px;
  }
}

@media screen and (max-width: 540px) {
  .tippedListWrapper .loadmoreBtn .btnText {
    font-size: 12px;
  }
}

@media screen and (max-width: 375px) {
  .tippedListWrapper .loadmoreBtn .btnText {
    font-size: 11px;
  }
}
