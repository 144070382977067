.postWrapper {
  border: 2px solid #E4E4E4;
  border-radius: 8px;
  margin-bottom: 16px;
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
  .post {
    padding: 15px;
    .header {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
    }
    .lockedPostText {
      font-size: 16px;
      position: relative;
      margin-bottom: 16px;
      .lockedOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 100%
        );
      }
    }
    .postTextWrapper {
      flex-grow: 1;
      padding-top: 0;
      padding-bottom: 4px;
      .postText :global(.richcontent) {
        cursor: pointer;
        overflow: hidden;
        text-overflow: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: pre-line;
        padding-bottom: 1px;
        .text {
          font-size: 16px;
          font-family: var(--main-font-family);
          a {
            pointer-events: none;
          }
        }
      }
    }
    .footer {
      width: 100%;
      display: flex;
      align-items: center;
      .readMore {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        font-weight: 600;
        font-size: 12px;
        cursor: pointer;
        a {
          color: #04b56a;
          &:hover {
            background: rgba(0, 0, 0, 0.05);
            border-radius: 1px;
          }
        }
      }
    }
  }
}

.rightSide {
  display: flex;
  align-items: center;
  margin-left: auto;
  .paidLabel {
    margin-right: 8px;
    flex-shrink: 0;
    width: 20px;
    * {cursor: default;}
  }
  .timeSince {
    margin-right: 12px;
    color: #666666;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.threeDotsBtn {
  &:hover,
  &[data-is-open='true'] {
    background: #eeeeee;
    border-radius: 50%;
  }
}

@media screen and (max-width: 767px) {
  .readMore a {
    font-size: 14px !important;
  }
  .postWrapper {
    border: 3px solid #E4E4E4;
    position: relative;
    margin-bottom: 10px;
    width: 100%;
    .post {
      padding: 12px;
      .postText :global(.richcontent) {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        .text {
          font-size: 13px;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .readMore a {
    font-size: 12px !important;
  }
  .postWrapper {
    .post {
      .postText :global(.richcontent) {
        .text {
          font-size: 11px;
        }
      }
    }
  }
}
