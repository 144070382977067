.postWrapper {
  margin-bottom: 16px;
  border-radius: 8px;
  border: 3px solid #E4E4E4;
  &.pinned {
    position: relative;
    border-radius: 0px;
    background-color: #FAEED0 !important;
    margin-left: -24px;
    border: none;
    margin-right: -24px;
    margin-bottom: 8px;
    padding: 36px 24px 12px 24px;
    .pinLabel {
      display: flex;
      align-items: center;
      position: absolute;
      left: 24px;
      top: 8px;
      span {
        color: #E57C00;
        margin-left: 3px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .post {
      border: 1px solid #E4E4E4;
    }
  }
  &.freePaidPost {
    position: relative;
    border-radius: 0px;
    background-color: #bff1dd !important;
    margin-left: -24px;
    border: none;
    margin-right: -24px;
    margin-bottom: 8px;
    padding: 36px 24px 12px 24px;
    .pinLabel {
      display: flex;
      align-items: center;
      position: absolute;
      left: 24px;
      top: 8px;
      span {
        color: var(--green-clr);
        margin-left: 3px;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
      }
    }
    .post {
      border: 1px solid #E4E4E4;
    }
  }
  &:hover {
    background: rgba(0, 0, 0, 0.02);
  }
  .post {
    border-radius: 8px;
    &.hasMedia {
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      border-top: none;
    }
    background-color: #fff;
    .header {
      display: flex;
      align-items: center;
      padding: 12px;
    }
    .symbols {
      padding-left: 12px;
      padding-bottom: 1px;
      display: flex;

      .symbol {
        margin-right: 5px;
        span {
          display: flex;
          align-items: center;
          padding: 4px 10px;
          background-color: var(--green-clr);
          color: #fff;
          font-family: 'Hiragino Kaku Gothic Pro';
          font-style: normal;
          font-weight: 700;
          width: fit-content;
          font-size: 10px;
          line-height: 15px;
          border: 1px solid var(--green-clr);;
          box-sizing: border-box;
          border-radius: 16px;  
        }
      }  
    }
    .tippedList {
      width: 100%;
      pointer-events: none;
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 4px;
    }
    .sharedPost {
      padding-left: 12px;
      padding-right: 12px;
    }
    .lockedPostText {
      font-size: 16px;
      position: relative;
      margin-bottom: 16px;
      .lockedOverlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          #ffffff 100%
        );
      }
    }
    .postTextWrapper {
      flex-grow: 1;
      padding-top: 8px;
      padding-bottom: 12px;
      padding-left: 12px;
      padding-right: 12px;
      cursor: pointer;
      .postText {
        display: flex;
        position: relative;
        cursor: pointer;
        .text {
          padding-right: 90px;
          font-size: 16px;
          font-family: var(--main-font-family);
          overflow: hidden;
          width: 100%;
          text-overflow: initial;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: pre-line;
          padding-bottom: 1px;
          a {
            pointer-events: none;
          }
        }
        .readmoreWrapper {
          position: absolute;
          bottom: 0px;
          font-size: 12px;
          line-height: 25px;
          padding-top: 1px;
          padding-bottom: 1px;
          flex-wrap: nowrap;
          right: 0;
          padding-left: 4px;
          z-index: 2;
          display: flex;
          background: #fff;
          align-items: center;
          text-align: right;
          .readmore {
            font-weight: 600;
            font-size: 12px;
            cursor: pointer;
            color: #04b56a;
            margin-left: auto;
            display: flex;
            text-decoration: underline;
            margin-left: 5px;
            &:hover {
              background: rgba(0, 0, 0, 0.05);
              border-radius: 1px;
            }  
          }
        }  
      }
      .postText.noMedia .text {
        -webkit-line-clamp: 3;
      }
    }
    .footer {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}

.rightSide {
  display: flex;
  align-items: center;
  margin-left: auto;

  .paidLabel {
    margin-right: 8px;
    flex-shrink: 0;
    width: 28px;
    * {cursor: default;}
  }

  .timeSince {
    margin-right: 12px;
    color: #666666;
    font-size: 12px;
    &:hover {
      text-decoration: underline;
    }
  }
}

.threeDotsBtn {
  &:hover,
  &[data-is-open='true'] {
    background: #eeeeee;
    border-radius: 50%;
  }
}

@media screen and (max-width: 767px) {
  .readMore a {
    font-size: 12px !important;
  }
  .rightSide .timeSince {
    max-width: 160px;
  }
  .postWrapper {
    position: relative;
    margin-bottom: 10px;
    &.pinned {
      position: relative;
      border-radius: 0px;
      background-color: #FAEED0 !important;
      margin-left: -8px;
      margin-right: -8px;
      margin-bottom: 6px;
      padding: 36px 8px 12px 8px;
      .pinLabel {
        display: flex;
        align-items: center;
        position: absolute;
        left: 8px;
        top: 8px;
        span {
          color: #E57C00;
          margin-left: 3px;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
        }
      }  
    }
    &.freePaidPost {
      position: relative;
      border-radius: 0px;
      background-color: #bff1dd !important;
      margin-left: -8px;
      margin-right: -8px;
      margin-bottom: 6px;
      padding: 36px 8px 12px 8px;
      .pinLabel {
        display: flex;
        align-items: center;
        position: absolute;
        left: 8px;
        top: 8px;
        span {
          color: var(--green-clr);;
          margin-left: 3px;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
        }
      }  
    }
    .post {
      .postText :global(.richcontent), .postText .richcontent {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .readMore a {
    font-size: 12px !important;
  }
}
