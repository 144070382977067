.newPostReactionWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  &.isPostDetail {
    .line {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .postReActionsWrapper {
      margin-left: -10px !important;
      margin-right: -10px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      background: #fff !important;
    }
    .separate {
      background: #fff;
    }
  }
  &.smallSize {
    margin-left: 0 !important;
    margin-right: 0 !important;
    .line {
      margin-left: 0 !important;
      margin-right: 0 !important;
      display: none;
    }
    .postReActionsWrapper {
      padding-left: 6px !important;
      padding-right: 6px !important;
    }
  }
}

.line {
  border-bottom: 1px solid var(--light-gray-clr);
  margin-left: 10px;
  margin-right: 10px;
}

.postReActionsWrapper {
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.separate {
  height: 25px;
  display: flex;
  align-items: center;
  width: 5px;
}

.icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 6px 0px;
  margin-top: 3px;
  margin-bottom: 3px;
  border-radius: 3px;
  &:not(.viewCount) {
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }  
  }
  .numberWrapper {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      font-family: 'Hiragino Kaku Gothic Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: var(--dark-gray-clr);
    }
    .number {
      font-family: 'Hiragino Kaku Gothic Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      margin-left: 6px;
      color: var(--dark-gray-clr);
    }
  }
}

.shareMenuTrigger {
  position: relative;
  .shareMenuWrapper {
    position: absolute;
    bottom: 40px;
    width: 250px;
    &.smallSize {
      right: 0;
    }
    padding-bottom: 8px;
    padding-top: 8px;
    z-index: 1000;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background: #fff;
    .option {
      padding: 8px 6px;
      margin-left: 6px;
      margin-right: 6px;
      display: flex;
      justify-content: center;
      border-radius: 4px;
      align-items: center;
      .icon {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-right: 8px;
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Hiragino Kaku Gothic Pro';
        color: #000;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.05);
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .newPostReactionWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .line {
    border-bottom: 1px solid #eee;
  }
  .postReActionsWrapper {
    display: flex;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .icon {
    .numberWrapper {
      margin-left: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      .text {
        font-size: 11px;
        line-height: 11px;
      }
      .number {
        font-size: 11px;
        margin-top: 2px;
        line-height: 11px;
        margin-left: 3px;
      }
    }
  }
  .shareMenuTrigger {
    .shareMenuWrapper {
      .option {
        .icon {
          width: 16px;
          height: 16px;
        }
        .text {
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .icon {
    .numberWrapper {
      margin-left: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      .text {
        font-size: 11px;
        line-height: 11px;
      }
      .number {
        font-size: 11px;
        line-height: 11px;
      }
    }
  }
  .shareMenuTrigger {
    .shareMenuWrapper {
      .option {
        .text {
          font-size: 11px;
        }
      }
    }
  }
}
