.reportBox {
  display: flex;
  padding-bottom: 7px;
  .inputBox {
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    position: relative;
    &.expanded {
      background: #fafafa;
      border: 1px solid #f3f3f3;
      border-radius: 24px;
      .textInputWrapper {
        border: none;
      }
      .footer {
        padding: 0 8px 8px 8px;
      }
    }
    .textInputWrapper {
      font-size: 0;
      border-radius: 24px;
      border: 1px solid #f3f3f3;
      margin-bottom: 9px;
      .textInput {
        border-radius: 24px;
        width: 100%;
        outline: none;
        padding: 10px 16px;
        font-size: 16px;
        resize: none;
        background: #fafafa;
        border: none;
        &::placeholder {
          font-size: 16px;
          color: #666666;
        }
      }
    }
    .footer {
      display: flex;
      width: 100%;
      padding-bottom: 8px;
      position: relative;
      overflow: hidden;
      .rightSide {
        display: none;
        overflow: hidden;
        margin-left: auto;
        align-items: center;
        justify-content: center;
        white-space: nowrap;
        &.expanded {
          display: flex;
        }
        .progress {
          margin-right: 8px;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .reportBox {
    .inputBox {
      border-radius: 6px;
      &.expanded {
        border-radius: 10px;
        .footer {
          padding: 0 6px 6px 2px;
        }
      }
      .footer {
        padding-bottom: 2px;
        .rightSide {
          .progress {
            margin-right: 4px !important;
          }
        }
        .text {
          display: none;
        }
      }
    }
  }
}
