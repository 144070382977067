.tippedInfo {
  padding-left: 15px;
  margin-left: auto;
  flex-shrink: 0;
}
.tippedIcon {
  margin-right: 3px;
}

.deletedUser {
  display: flex;
  align-items: center;
  span {
    margin-left: 8px;
    font-family: Hiragino Kaku Gothic Pro;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #666666;
  }
}  
